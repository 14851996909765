import React from 'react';
import Container from 'common/src/components/UI/Container';

const PrivacySection = () => {
  return (
    <Container style={{ marginTop: '8rem' }}>
      <p>
        <b>
          <span>SalesOcean privacy policy</span>
        </b>
      </p>

      <p>
        <span>
          This privacy policy will explain how our organization uses the
          personal data we collect from you when you use our website.
        </span>
      </p>

      <p>
        <span>Topics:</span>
      </p>

      <ul>
        <li>
          <span>What data do we collect?</span>
        </li>
        <li>
          <span>How do we collect your data?</span>
        </li>
        <li>
          <span>How will we use your data?</span>
        </li>
        <li>
          <span>How do we store your data?</span>
        </li>
        <li>
          <span>What are your data protection rights?</span>
        </li>
        <li>
          <span>Limited Use requirements Google APIs</span>
        </li>
        <li>
          <span>What are cookies?</span>
        </li>
        <li>
          <span>How do we use cookies?</span>
        </li>
        <li>
          <span>What types of cookies do we use?</span>
        </li>
        <li>
          <span>How to manage your cookies</span>
        </li>
        <li>
          <span>Privacy policies of other websites</span>
        </li>
        <li>
          <span>Changes to our privacy policy</span>
        </li>
        <li>
          <span>How to contact us</span>
        </li>
        <li>
          <span>How to contact the appropriate authorities</span>
        </li>
      </ul>

      <p>
        <b>
          <span>What data do we collect?</span>
        </b>
      </p>

      <p>
        <span>SalesOcean collects the following data</span>
        <span>:</span>
      </p>

      <ul>
        <li>
          <span>Data for website performance and analytics. </span>
          <span></span>
        </li>
        <li>
          <span>
            If you would use our contact form, we will collect personal
            identification information{' '}
          </span>
          <span>(Name, email address, phone number, etc.)</span>
        </li>
      </ul>

      <p>
        <b>
          <span>How do we collect your data?</span>
        </b>
      </p>

      <p>
        <span>
          You directly provide SalesOcean with most of the data we collect. We
          collect data and process data when you:
        </span>
      </p>

      <ul>
        <li>
          <span>Use or view our website via your browser’s cookies.</span>
        </li>
        <li>
          <span>By filling in our contact form</span>
          <span></span>
        </li>
      </ul>

      <p>
        <b>
          <span>How will we use your data?</span>
        </b>
      </p>

      <p>
        <span>SalesOcean collects your data so that we can:</span>
      </p>

      <ul>
        <li>
          <span>
            Keep track of general analytics for our website (browser, device
            system, geographic area, etc.)
          </span>
          <span></span>
        </li>
        <li>
          <span>Contact you back when you filled in our contact form</span>
          <span></span>
        </li>
      </ul>

      <p>
        <b>
          <span>How do we store your data?</span>
        </b>
      </p>

      <p>
        <span>SalesOcean securely stores your data at </span>
        <span>
          our servers that are only accessible through employees of SalesOcean
          and will not be shared with 3<sup>rd</sup> parties.
        </span>
      </p>

      <p>
        <b>
          <span>What are your data protection rights?</span>
        </b>
      </p>

      <p>
        <span>
          SalesOcean would like to make sure you are fully aware of all of your
          data protection rights. Every user is entitled to the following:
        </span>
      </p>
      <p>
        <b>
          <span>Limited Use requirements Google APIs</span>
        </b>
      </p>
      <p>
        <span>
          SalesOcean use of information received from Google APIs will adhere to
          the{' '}
          <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
            API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </span>
      </p>
      <p>
        <b>
          <span>The right to access</span>
        </b>
        <span>
          &nbsp;– You have the right to request SalesOcean for copies of your
          personal data. We may charge you a small fee for this service.
        </span>
      </p>

      <p>
        <b>
          <span>The right to rectification</span>
        </b>
        <span>
          &nbsp;– You have the right to request that SalesOcean correct any
          information you believe is inaccurate. You also have the right to
          request SalesOcean to complete the information you believe is
          incomplete.
        </span>
      </p>

      <p>
        <b>
          <span>The right to erasure</span>
        </b>
        <span>
          &nbsp;– You have the right to request that SalesOcean erase your
          personal data, under certain conditions.
        </span>
      </p>

      <p>
        <b>
          <span>The right to restrict processing</span>
        </b>
        <span>
          &nbsp;– You have the right to request that SalesOcean restrict the
          processing of your personal data, under certain conditions.
        </span>
      </p>

      <p>
        <b>
          <span>The right to object to processing</span>
        </b>
        <span>
          &nbsp;– You have the right to object to SalesOcean’s processing of
          your personal data, under certain conditions.
        </span>
      </p>

      <p>
        <b>
          <span>The right to data portability</span>
        </b>
        <span>
          &nbsp;– You have the right to request that SalesOcean transfer the
          data that we have collected to another organization, or directly to
          you, under certain conditions.
        </span>
      </p>

      <p>
        <span>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at:{' '}
          <a href="mailto:info@salesocean.io">info@salesocean.io</a>
        </span>
      </p>
      <p>
        <b>
          <span>Cookies</span>
        </b>
      </p>

      <p>
        <span>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology
        </span>
      </p>

      <p>
        <span>
          For further information, visit{' '}
          <a href="https://allaboutcookies.org">allaboutcookies.org</a>.
        </span>
      </p>

      <p>
        <b>
          <span>How do we use cookies?</span>
        </b>
      </p>

      <p>
        <span>
          SalesOcean uses cookies in a range of ways to improve your experience
          on our website, including:
        </span>
      </p>

      <ul>
        <li>
          <span>Understanding how you use our website</span>
        </li>
        <li>
          <span>Keep track of your agreement for us to collect data</span>
          <span></span>
        </li>
      </ul>

      <p>
        <b>
          <span>What types of cookies do we use?</span>
        </b>
      </p>

      <p>
        <span>
          There are a number of different types of cookies, however, our website
          uses:
        </span>
      </p>

      <ul>
        <li>
          <span>
            Functionality – SalesOcean uses these cookies so that we recognize
            you on our website and remember your previously selected
            preferences. These could include what language you prefer and
            location you are in. A mix of first-party and third-party cookies
            are used.
          </span>
        </li>
      </ul>

      <p>
        <b>
          <span>How to manage cookies</span>
        </b>
      </p>

      <p>
        <span>
          You can set your browser not to accept cookies, and the above website
          tells you how to remove cookies from your browser. However, in a few
          cases, some of our website features may not function as a result.
        </span>
      </p>

      <p>
        <b>
          <span>Privacy policies of other websites</span>
        </b>
      </p>

      <p>
        <span>
          The SalesOcean website contains links to other websites. Our privacy
          policy applies only to our website, so if you click on a link to
          another website, you should read their privacy policy.
        </span>
      </p>

      <p>
        <b>
          <span>Changes to our privacy policy</span>
        </b>
      </p>

      <p>
        <span>
          SalesOcean keeps its privacy policy under regular review and places
          any updates on this web page. This privacy policy was last updated on{' '}
        </span>
        <span>4</span>
        <span> </span>
        <span>Juli</span>
        <span> </span>
        <span>2020</span>
        <span>.</span>
      </p>

      <p>
        <b>
          <span>How to contact us</span>
        </b>
      </p>

      <p>
        <span>
          If you have any questions about SalesOcean’s privacy policy, the data
          we hold on you, or you would like to exercise one of your data
          protection rights, please do not hesitate to contact us.
        </span>
      </p>

      <p>
        <span>Email us at:</span>
        <span>
          {' '}
          <a href="mailto:info@salesocean.io">info@salesocean.io</a>
        </span>
      </p>

      <p>
        <b>
          <span>How to contact the appropriate authority</span>
        </b>
      </p>

      <p>
        <span>
          Should you wish to report a complaint or if you feel that SalesOcean
          has not addressed your concern in a satisfactory manner, you may
          contact the Information Commissioner’s Office.
        </span>
      </p>

      <p>
        <span>Email:</span>
        <span>
          {' '}
          <a href="mailto:gdpr@salesocan.io">gdpr@salesocean.io</a>
        </span>
      </p>
    </Container>
  );
};

export default PrivacySection;
